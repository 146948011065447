/* You can add global styles to this file, and also import other style files */

@import 'node_modules/@core/core/src/assets/styles';

.pt-10{
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-10 {
  padding-right: 10px;
}

.pt-24 {
  padding-top: 1.5rem;
}

.pb-24 {
  padding-bottom: 1.5rem;
}

.mt-10 {
  margin-top: 10px !important;
}

.mtb-12 {
  margin: 0.75rem 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.word {
  overflow-wrap: anywhere;
}

.w-28 {
  min-width: 28rem;
}

.w-100-percent {
  width: 100% !important;
}

.w-18-rem {
  width: 18.5rem !important;
}

.field-padding-top {
  padding-top: 1.5rem;
}

.screen-height {
  height: revert;
}

.custom-mat-error {
  display: flex !important;
  align-items: center;
  font-size: 0.8571428571rem;
}
.custom-error-icon {
  margin-right: 4px;
}
.text-error {
  width: 14rem;
}

.request-name {
  font-weight: 100;
}

.previous-page {
  width: 10rem;
  border: none;
  background: none;
  position: relative;
  left: -1.5rem;
}

.previous-page:hover {
  cursor: pointer;
}

.previous-page-name {
  color: #CC0000;
  font-weight: bold;
  position: relative;
  bottom: 0.5rem;
  padding-left: 5px;
}
/* common review style */
.review-contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--EPA-16, 16px);
}


/* common hint style */

.pageHint {
  padding-top: 24px;
  line-height: 21px;
  width: 100%;
  overflow: hidden;

  .hint-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 80vw;
  }
}

.audit-info-container {
  label {
    color: #767676;
  }
  .field-width {
    min-width: 14.5em;
  }
  .field-padding {
    padding-top: 1.5rem;
  }
  .client-name-input {
    top: 0.85em;
    min-width: 15rem;
  }
  .audit-request-type {
    margin-right: 1rem;
  }
  .audit-info-field {
    padding-right: 15px;
    min-width: 20rem;
  }
  .audit-firm {
    p {
      margin: 8px 0 0;
    }
  }
  @media only screen and (min-width : 921px) {
    .audit-info-content {
      display: flex;
    }
    //.pageHint {
    //  max-width: 710px;
    //  .hint-text {
    //    -webkit-line-clamp: 4;
    //  }
    //}
    .info-review {
      display: flex;
      align-items: center;
      align-content: flex-start;
      gap: 16px var(--EPA-16, 16px);
      padding-top: 0.75rem;
      align-self: stretch;
      flex-wrap: wrap;
    }
  }
  .review-original-client-name{
    min-width: 15rem;
  }
}

/** Common style **/
.btn-position{
  float: right !important;
  position: relative;
  bottom: 1.5rem;
}

.notes-section {
  width: 50rem !important;
  margin-bottom: 10px;
}

.btn-field {
  padding: 1.5rem 0;
}

.label-gray {
  color: #767676;
}

.checkbox-align-col-4 {
  margin-top: 20px;
  list-style-type: disc;
  -webkit-columns: 4;
  columns: 4;
  list-style-position: outside;
}

.checkbox-audit-type-col-align {
  margin-top: 20px;
  list-style-type: disc;
  -webkit-columns: 5;
  columns: 5;
  list-style-position: outside;
}

.tab-label-container {
  display: flex;
}

.tab-label-text {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.unread-notes-count {
  border: 1.5px solid;
  padding: 1px 5px;
  border-radius: 1rem;
  margin-left: 5px;
  margin-right: 10px;
}


.dot-icon {
  position: relative;
  left: -0.2rem;
}

.envelope-icon {
  position: relative;
  right: 0.5rem;
}

.notes-exist-content {
  position: relative;
  bottom: 0.3rem;
}

.unread-dot-icon {
  color: red;
  position: relative;
  width: 2.3rem !important;
  height: 2.3rem !important;
}

.unread-icon-alignment {
  bottom: 0.5rem;
  right: 2.2rem;
}

.status-text {
  position: relative;
  left: 2.6rem;
  padding-right: 2px;
}

.unread-status-text {
  position: relative;
  right: 2.3rem;
  padding-right: 2px;
}

@media only screen and (min-width : 921px) {
  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}
.checkbox-row{
  width: 17rem !important;
  margin-bottom: 20px;
}

.title-icon {
  bottom: 2.1rem;
}

.audit-contact-container {
  .general-mail {
    width: 20em;
  }
  .subcontractor-btn {
    padding: 1.5rem 0;
  }
  //.pageHint {
  //  max-width: 710px;
  //  //.hint-text {
  //  //  -webkit-line-clamp: 3;
  //  //}
  //}
}

.contact-view-flex-container {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
}
.contact-view-div {
  margin: 0 20px 20px 0;
}

.audit-contact-custom-model {
  max-width: 80vw !important;
  width: 60vw;
  mat-dialog-container {
    height: auto !important;
  }
}

.audit-small-model {
  width: 35vw !important;
  mat-dialog-container {
    height: auto !important;
  }
}

.audit-info-type-container {
  padding: 1.5rem 0;
  .custom-info-title {
    padding: 1.5rem 0;
  }
  .custom-info-description {
    padding-bottom: 1.5rem;

  }
  .custom-request{
    padding-bottom: 1.5rem;
  }
  .custom-info-array {
    display: flex;
    //width: 80rem;
    flex-wrap: wrap;
    padding-bottom: 10px;
    ul {
      height: 300px;
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 15px;
      display: flex;
      flex-direction: row;
      width: 21rem;
      margin-right: 10px;
    }
    li {
      width: 21rem;
      word-wrap: break-word;
      white-space: pre-wrap;
      padding-right: 21px
    }
    button {
      vertical-align: -7px
    }
  }
  .save-audit-type {
    padding: 1.5rem 0;
  }
  //.pageHint {
  //  max-width: 710px;
  //  .hint-text {
  //    -webkit-line-clamp: 4;
  //  }
  //}

}


.template-title{
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    white-space: pre-wrap;
  }
}

.custom-hint {
  font-size: 12px;
}

.upload-by-text {
  word-break: break-all;
}

.def-body {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .part-a {
    float: left;
    width: 20%;
    padding: 15px 0;
  }

  .part-b {
    float: left;
    width: 80%;
    padding: 15px 0;
  }
}

.data-notes .mat-form-field{
  width: 100%;
  padding: 10px 0;
}
.data-notes textarea {
  height: 3.4rem;
}
.delete-container .template-body {
  padding-top: 10px;
}


.dialog-actions {
  padding-top: 24px !important;
}
.data-title .mat-form-field {
  width: 275px;
  padding: 10px 0;
}

.template-body {
  div {
    padding: 15px 0;
    color: #767676;
  }
  h2, p {
    white-space: pre-wrap;
  }
}


.customInfoDialog {
  .mat-dialog-container {
    width: 1064px !important;
    height: auto !important;
  }
}

.viewInfoDialog {
  .mat-dialog-container {
    width: 1064px !important;
    height: auto !important;
  }
}

.typeDialog{
  .mat-dialog-container {
    width: 1064px !important;
    height: auto !important;
  }
}

.infoDialog{
  .mat-dialog-container {
    width: 1064px !important;
    height: 44.79rem !important;
  }
}

.ndaInfoDialog {
  .mat-dialog-container {
    width: 500px !important;
    height: auto !important;
  }
}

.deleteInfoDialog {
  .mat-dialog-container {
    width: 500px !important;
    height: auto !important;
  }
}

.audit-type-dialog {
  width: 80% !important;
  .mat-dialog-container {
    height: auto !important;
  }
}

.info-icon {
  height: 1rem !important;
  padding-bottom: 4px !important;
}

.audit-role-container {
  //.pageHint {
  //  max-width: 710px;
  //  .hint-text {
  //    -webkit-line-clamp: 4;
  //  }
  //}
}

.nda-info-modal-body {
  width: 100%;
  .part-a {
    padding: 15px 0;
  }
}

.center-close-x {
  mat-icon {
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 511px) {
  header {
    display: block !important;
    padding: 12px 0 40px 25px !important;
  }
  .username-signout-container {
    padding-top: 20px !important;
  }
  .sign-out {
    padding-left: 0 !important;
  }
}

@media screen  and (max-width: 417px) {
  .branded-product-name{
    padding-top: 5px !important;
  }
  .username-signout-container {
    padding-top: 40px !important;
  }
}

@media screen and (max-width: 276px) {
  .username-signout-container {
    padding-top: 20px !important;
  }
}

.mat-vertical-content {
  padding: 0 0 0 0 !important;
}

.mat-step-label {
  white-space: initial !important;
}

.mat-select-placeholder {
  color: black !important;
}

.model-container {
  span.mat-checkbox-label {
    white-space: normal !important;
  }
}

@media screen and (max-width: 420px) {
  .def-body {
    flex-direction: column;
  }
  .btn-field {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  .screen-height {
    height: 100%;
  }
  .audit-info-title .audit-info-content {
    display: flex;
    flex-direction: row;
    label {
      width: 10.625rem;
    }
  }

  .customInfoDialog {
    .mat-dialog-container {
      width: auto !important;
    }
  }

  .data-title .mat-form-field {
    width: auto;
  }
  .data-notes .mat-form-field{
    width:100%;
  }
  .data-notes textarea {
    height: 12.4rem;
  }

  .dialog-actions {
    flex-wrap: nowrap !important;
    flex-direction: column;
    gap: 1rem;
    .pds-btn-white {
      width: 80%;
    }
  }

  .audit-type-dialog {
    width: auto !important;
    .mat-dialog-container {
      height: auto !important;
    }
  }

  .audit-small-model {
    width: auto !important;
    mat-dialog-container {
      height: auto !important;
    }
  }

  .review-contents {
    flex-direction: column;
    align-items: flex-start;
    overflow-wrap: break-word;

    .audit-request {
      max-width: 85%;
    }
  }

  .audit-role-container {
    .radio-group {
      flex-direction: column !important;
      width: 20rem !important;
    }
    span.mat-radio-label-content {
      white-space: normal !important;
    }
  }

}
@media screen and (max-width: 920px) {
  .mat-form-field-infix {
    width: 300px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 1em 0 !important;
  }

  .notes-section {
    width: 100% !important;
  }
}

.mat-step-header {
  height: auto !important;
}

.tooltip-bottom {
  white-space: pre-line !important;
  text-align: left !important;
}

/********** Component style **********/
.external-dashboard-container {
  .mat-sort-header-container:not(.mat-sort-header-sorted) {
    .mat-sort-header-arrow {
      opacity: .5 !important;
    }
  }
}

